/* src/components/Roadmap.css */

.roadmap-container {
    margin: 20px;
    padding: 20px;
  }

/* Style pour le formulaire d'ajout */
form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  form > * {
    margin: 5px;
  }
  
  /* Style pour les boutons d'action */
  .IconButton {
    padding: 5px;
  }
  
  /* Style pour les sélecteurs avec couleur d'état */
  .MuiSelect-select {
    padding: 10px;
    border-radius: 4px;
  }
  
  /* Ajouter des styles responsives si nécessaire */
  @media (max-width: 768px) {
    form {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .MuiTableCell-root {
      padding: 8px;
    }
  
    .MuiSelect-select {
      min-width: 100px;
    }
  }
